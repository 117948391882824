import {isMembersAreaInstalled} from '@wix/members-area-integration-kit';
import {doTransaction} from '../transaction';
import {ecomAppDefID} from '../constants';
import {PageMap} from '@wix/wixstores-client-core/dist/es/src/constants';
import {STORES_APP_DEF_ID} from '../../stores-editor-script/constants';
import {DependantApps} from '../services/DependantApps';
import {StyleParams} from '../services/StyleParams';
import {EditorSDK, StyleParam} from '@wix/platform-editor-sdk';
import {createWishlistApi, InstallationOrigin} from './wishlist/wishlistActions';
import {AppApiModel} from '@wix/wixstores-client-core/dist/src/types/app-api-model';
import {clickToPreviewWishlistInMembersSfe} from '@wix/bi-logger-ec-sf/v2';
import {Logger as WebBiLogger} from '@wix/web-bi-logger/dist/src/types';

export function createAppApi({
  sdk,
  dependantApps,
  styleParams,
  t,
  webBiLogger,
}: {
  sdk: EditorSDK;
  dependantApps: DependantApps;
  styleParams: StyleParams;
  t: (keyToTranslate: string) => string;
  webBiLogger: WebBiLogger;
}): AppApiModel {
  const wishlistApi = createWishlistApi({sdk, dependantApps, t, webBiLogger});
  return {
    isMembersInstalled: () => isMembersAreaInstalled(),
    installMembersAreaAndWishlistPage: (openerCompRef: string) =>
      wishlistApi.installWishlist({
        openerCompRef,
        origin: InstallationOrigin.ProductPageSettings,
        installMembersArea: true,
      }),
    installWishlist: (openerCompRef: string) =>
      wishlistApi.installWishlist({
        openerCompRef,
        origin: InstallationOrigin.ProductPageSettings,
        installMembersArea: false,
      }),
    uninstallWishlist: () => wishlistApi.uninstallWishlist(),
    isAppInstalled: (appDefinitionId: string) => {
      return dependantApps.isAppInstalled(appDefinitionId);
    },
    setCheckoutStyleParam(type: StyleParam['type'], key: string, param: {value: any}): Promise<void> {
      return doTransaction(sdk, () => styleParams.setCheckoutStyleParam(type, key, param));
    },
    addShopPage: (title?: string) => {
      return doTransaction(sdk, async () => {
        const allSitePages = await sdk.pages.data.getAll('');
        const ecomPages = allSitePages.filter(({managingAppDefId}) => managingAppDefId === ecomAppDefID);
        const managingAppDefId = ecomPages.some((page) => page.tpaPageId === PageMap.PRODUCT)
          ? ecomAppDefID
          : STORES_APP_DEF_ID;
        return sdk.tpa.add.component('', {
          appDefinitionId: ecomAppDefID,
          managingAppDefId,
          page: {pageId: 'product_gallery', title},
          componentType: 'PAGE',
        });
      });
    },
  };
}

export async function navigateToWishlist(dependantApps: DependantApps, webBiLogger) {
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  webBiLogger.report(clickToPreviewWishlistInMembersSfe({}));
  return dependantApps.navigateToWishlist();
}
